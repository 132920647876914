<template>
  <v-data-table
    :headers="headers"
    :items="funcionarios"
    :items-per-page="15"
    :page="pageNumber"
    class="elevation-1 tabela-funcionarios"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Funcionários por página:',
      'items-per-page-all-text': 'Todos',
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
      'items-per-page-options': [100, 200, 350, 500],
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.uuid`]="{ item }">
      <v-btn
        v-if="funcionarioExibir"
        small
        depressed
        color="fmq_gray"
        dark
        @click="gotToFuncionario(item.id)"
        >Detalhes</v-btn
      >
    </template>

    <template v-slot:[`item.status`]="{ item }">
      {{ item.status.toUpperCase() }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "FuncionariosTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    funcionarios: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageText: {
      type: String,
      default: "-",
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  methods: {
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? "DESC" : "ASC"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotToFuncionario(id) {
      this.$router.push({ name: "FuncionarioEditar", params: { id: id } });
    },
  },
  computed: {
    funcionarioExibir() {
      return this.$store.state.user.data.permissoes.includes(
        "funcionario.exibir"
      );
    },
  },
};
</script>
<style lang="scss">
.tabela-funcionarios tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-funcionarios tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}
</style>
