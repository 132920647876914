var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 tabela-funcionarios",attrs:{"headers":_vm.headers,"items":_vm.funcionarios,"items-per-page":15,"page":_vm.pageNumber,"locale":"pt-BR","loading-text":"Carregando","no-data-text":"Nenhum dado encontrado","loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-text': 'Funcionários por página:',
    'items-per-page-all-text': 'Todos',
    'show-first-last-page': true,
    'show-current-page': true,
    'page-text': _vm.pageText,
    'items-per-page-options': [100, 200, 350, 500],
  }},on:{"update:options":_vm.handleFilter},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [_c('th',{key:index},[_vm._t(header.value)],2)]})],2)]},proxy:true},{key:`item.uuid`,fn:function({ item }){return [(_vm.funcionarioExibir)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.gotToFuncionario(item.id)}}},[_vm._v("Detalhes")]):_vm._e()]}},{key:`item.status`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.status.toUpperCase())+" ")]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }